<template>
  <a-modal :visible="editShow" :centered="true"
           :title="modalTitle"
           @ok="save"
           @cancel="handleCloseModal"
           :closable="false"
           :width="modalWidth">

    <a-tabs :active-key="activeTab" @change="handleChangedTab" :animated="false">
      <a-tab-pane key="basic" :tab="$t('domain.BasicConfiguration')">
        <BasicConfiguration
            ref="basicConfiguration"
            :domain-edit-disabled="!sourceRule.isNew()"
            :domain="domain"
            :description.sync="description"
            v-on:update:domain="handleDomainUpdate"
            v-on:update:description="handleDescriptionUpdate"
            :upstream-targets="domainFormData.upstream.targets"
            v-on:update:upstream-targets="handleTargetsUpdate"
            :service-protocol="domainFormData.service.protocol"
            v-on:update:service-protocol="handleServiceProtocolUpdate"
            :domain-action="domainAction"
            v-on:toggle-redirect-domain-action="handleRedirectDomainAction"
            :redirect-url="redirectUrl"
            v-on:update:redirect-url="handleRedirectUrlUpdate"
            :field-errors="field_errors"
        />
      </a-tab-pane>

      <a-tab-pane key="advanced-configuration" :tab="$t('domain.AdvancedConfiguration')">
        <AdvancedConfiguration :domain="sourceRule"
                               :plugins.sync="domainFormData.plugins"
                               :plugin-errors="pluginErrors"
                               :screenSize="screenSize"/>
      </a-tab-pane>

      <a-tab-pane key="security" :tab="$t('domain.GuardConfiguration')">
        <GuardConfiguration
            :domain="sourceRule"
            :plugins.sync="domainFormData.plugins"
            :plugin-errors="pluginErrors"
            :tags.sync="domainFormData.service.tags"
            :screenSize="screenSize"
            ref="guardConfiguration"></GuardConfiguration>
      </a-tab-pane>

    </a-tabs>

    <template slot="footer">
      <footer class="d-flex justify-content-between">
        <a-button-group>
          <a-popconfirm
              :title="$t('rule.ConfirmRuleDelete')"
              :ok-text="$t('Yes')"
              :cancel-text="$t('No')"
              @confirm="remove"
          >
            <a-button
                color="danger"
                id="rule-delete"
                type="submit"
                v-if="!sourceRule.isNew()"
                :disabled="inLoadingState"
                :loading="loadingRemove"
            >
              <a-icon v-if="!inLoadingState" type="delete"/>
              {{ $t('Delete') }}
            </a-button>                        
          </a-popconfirm>
          
        </a-button-group>

        <div>
          <a-button @click="handleCloseModal" color="danger" :disabled="inLoadingState">{{ $t('Cancel') }}</a-button>
          <a-button @click="save"
                    :disabled="isSaveButtonDisabled"
                    :loading="inLoadingState"
                    type="primary">
            <a-icon v-if="!inLoadingState" type="save"/>
            {{ $t('Save') }}
          </a-button>
        </div>
      </footer>
    </template>
  </a-modal>
</template>


<script>
import { isEqual, cloneDeep, has } from 'lodash';
import isValidDomain from 'is-valid-domain';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import GuardConfiguration from '@/views/domain/GuardConfiguration';
import {DOMAIN_TRAFFIC_ACTIONS, MODAL_STATES, PLUGIN_NAMES, SCREEN_SIZES, SCREEN_TYPES } from '@/utilities/constants';
import {deleteRule} from '@/utilities/api';
import BasicConfiguration from './BasicConfiguration';
import axios from '@/plugins/axios';
import AdvancedConfiguration from '@/views/domain/EditDomain/AdvancedConfiguration';
import { bus } from '@/main';

const DEFAULT_ACTIVE_TAB = 'basic';

export default {
  name: "EditRule",
  mixins: [DomainValidationMixin],
  components: {
    AdvancedConfiguration,
    BasicConfiguration,
    GuardConfiguration,
  },
  props: {
    sourceRule: Object,
    editShow: Boolean,
    is_root: Boolean,
    isPaused: Boolean
  },
  computed: {
    pluginErrors: function () {
      if (!this.plugin_errors) {
        if (!this.field_errors) {
            return [];
          }
    
          return [this.field_errors['plugins']] || [];
      } else {
        return [this.plugin_errors];
      }
    },
    domain: function () {
      if (!this.domainFormData.hosts || (this.domainFormData.hosts && this.domainFormData.hosts.length == 0))
        return '';

      return this.domainFormData.hosts[0].toLowerCase();
    },
    domainAction: function () {
      for (let p of this.domainFormData.plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          return DOMAIN_TRAFFIC_ACTIONS.REDIRECT;
        }
      }

      return DOMAIN_TRAFFIC_ACTIONS.UPSTREAM;
    },
    redirectUrl: function () {
      const plugins = this.domainFormData.plugins;

      for (let p of plugins) {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          return p.config.redirect_url;
        }
      }

      return '';
    },
    modalTitle() {
      if (this.sourceRule.isNew()) {
        return this.$t('rule.RuleConfiguration');
      }

      return this.$t('rule.RuleConfiguration') + ": " + this.sourceRule.getMainHost();
    },
    modalWidth() {
      if( this.screenSize === SCREEN_TYPES.XS ){
          return '100%';
      }

      if( this.screenSize === SCREEN_TYPES.SM){
        return '75%';
      }

      if( this.screenSize === SCREEN_TYPES.MD ){
        return '65%';
      }

      return "50%";
    },
    inLoadingState: function () {
      return (this.modalState === MODAL_STATES.SAVING) || this.loadingRemove;
    },
    isDomainFormDataDirty: function () {
      return !isEqual(this.domainFormData, this.frozenDomainFormData);
    },
    isSaveButtonDisabled: function () {
      return false;
    },
  },
  watch: {
    editShow() {
      if (this.editShow) {
        this.refresh();
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleScreenResize);
  },
  data() {
    const domainFormData = {
      name: '',
      description: '',
      hosts: [''],
      protocols: ['http', 'https'], // this is the default in Kong
      upstream: {
        targets: []
      },
      service: {
        protocol: '',
        tags: []
      },
      plugins: [],
    };

    return {
      description: '',
      name: '',
      domainFormData: domainFormData,
      frozenDomainFormData: domainFormData,
      frozenPlugins: [],
      field_errors: null,
      activeTab: DEFAULT_ACTIVE_TAB,
      guardConfigPendingValues: {},
      pendingValues: {},
      formValidStates: {},
      mutatedShow: false,
      loadingRemove: false,
      modalState: MODAL_STATES.IDLE,
      screenSize: SCREEN_TYPES.LG,
      plugin_errors: null,
    };
  },
  methods: {
    refresh() {
      this.domainFormData.name = this.sourceRule.getName();
      this.domainFormData.description = this.sourceRule.getDescription();
      this.domainFormData.hosts = this.sourceRule.getHosts();
      this.domainFormData.protocols = this.sourceRule.getProtocols();
      this.domainFormData.upstream.targets = this.sourceRule.getUpstreamTargets();
      this.domainFormData.service.protocol = this.sourceRule.getServiceProtocol();
      this.domainFormData.service.tags = this.sourceRule.getServiceTags();
      this.description = this.sourceRule.getDescription();
      // this.name = this.sourceRule.getName();
      this.frozenDomainFormData = JSON.parse(JSON.stringify(this.domainFormData)); 
      
      if (this.domainFormData.service.tags.includes('paused="1"')) {
        this.isPaused = true;
      } else {
      }
      
      // reset to first tab
      this.activeTab = DEFAULT_ACTIVE_TAB;
      this.modalState = MODAL_STATES.IDLE;
      this.field_errors = null;

      this.preparePlugins();

      this.domainFormData.name = this.sourceRule.getName();
      
    },
    async preparePlugins() {
      this.domainFormData.plugins = cloneDeep(this.sourceRule.getPlugins());
      this.frozenPlugins = JSON.parse(JSON.stringify(this.domainFormData.plugins));
    },
    handleScreenResize(){
      const width = document.documentElement.clientWidth;

      if( width <= SCREEN_SIZES.XS ){
        this.screenSize = SCREEN_TYPES.XS;
        return;
      }

      if( width <= SCREEN_SIZES.SM ){
        this.screenSize = SCREEN_TYPES.SM;
        return;
      }

      if( width <= SCREEN_SIZES.MD ){
        this.screenSize = SCREEN_TYPES.MD;
        return;
      }

      this.screenSize = SCREEN_TYPES.LG
    },
    clearFieldError(field) {
      if (!this.field_errors)
        return;

      if (!this.field_errors[field])
        return;

      const f = {...this.field_errors};
      delete f[field];

      this.field_errors = f;
    },
    handleCloseModal() {
      bus.$emit('emptyNewTargetName');
      this.$emit('hide');
    },
    async handlePauseModal(domain) { 
      try {
        await axios({
          method: "POST",
          url: `domain/pause_restore/`,
          data: {
            hosts: this.domainFormData.hosts,
            paused: this.isPaused ? false : true,            
          },
        });
      } catch ({error, errors}) {
        const errorData = error.response.data;
        this.field_errors = errorData.field_errors;
        this.plugin_errors = errorData.plugins;

        this.notifyError(this.$t('rule.RuleSavingFailed'));
        this.modalState = MODAL_STATES.IDLE;
        return;
      }
      
      this.notifySuccess(this.$t('rule.RuleSavedSuccessfully'));
      this.modalState = MODAL_STATES.IDLE;
      this.$emit('refresh');
      this.handleCloseModal();
    },
    handleDomainUpdate(domain) {
      const [_, ...tail] = this.domainFormData.hosts;
      const _domain = (domain || '').trim().toLowerCase();

      this.domainFormData.hosts = [_domain, ...tail];
      this.domainFormData.name = _domain;
      this.name = _domain;
    },
    handleDescriptionUpdate(description) {
      this.domainFormData.description = description;
      this.description = description;
    },    
    handleTargetsUpdate(targets) {
      this.domainFormData.upstream.targets = targets;
      this.clearFieldError('upstream');
    },
    handleServiceProtocolUpdate(serviceProtocol) {
      this.domainFormData.service.protocol = serviceProtocol;
      const port = this.domainFormData.upstream.targets[0].target.split(':')
      if ((port[1] === "80" || port[1] === "8080") && serviceProtocol === 'https') {
        this.notifyError(this.$t('message.InputUpstreamVerificationError', {
          0: this.domainFormData.upstream.targets[0].target,
          1: port[1],
          2: serviceProtocol
        }));
      }
      else if (port[1] === "443" && serviceProtocol === 'http') {
        this.notifyError(this.$t('message.InputUpstreamVerificationError', {
          0: this.domainFormData.upstream.targets[0].target,
          1: port[1],
          2: serviceProtocol
        }));
      }
      if (serviceProtocol == 'https') {
        this.domainFormData.protocols = ['https', 'http'];
      } else {
        this.domainFormData.protocols = ['http', 'https'];
      }
      this.clearFieldError('protocol');
    },
    remove() {
      this.loadingRemove = true;
      deleteRule({ruleId: this.sourceRule.getId(), cname: this.$store.getters.cname})
          .then(() => {
            this.$emit('refresh');
            this.$emit('hide');
          })
          .catch((error) => {
            if (error.response && error.response.data.detail) {
              this.notifyError(this.$t('action.fail_with_msg', {msg: error.response.data.detail}));
            } else {
              this.notifyError(this.$t('action.fail'));
            }
          })
          .finally(() => {
            this.loadingRemove = false;
          });
    },
    rule_validator(val) {
          return val == "" || /^[a-zA-Z0-9_. -]+$/.test(val);
    },
    async save() {  
      if (this.domainFormData.name === '' || this.domainFormData.name === undefined) {
        this.notifyError(this.$t('message.InputDataVerificationErrorOn', {on: this.$t('domain.RuleName')}))
        return;
      }
      if (this.domainFormData.name === '' ||  !this.rule_validator(this.domainFormData.name)) {
          this.notifyError(this.$t('message.RuleValidationError'))
          return;
      }
      if (this.domainAction === DOMAIN_TRAFFIC_ACTIONS.UPSTREAM) {
        this.field_errors = {upstream: []};
        if (this.domainFormData.upstream.targets.length > 0) {
          if (!this.domainFormData.service.protocol || this.domainFormData.service.protocol.length === 0) {
            this.notifyError(this.$t('message.MissingProtocol'));
            this.modalState = MODAL_STATES.IDLE;
            return;
          }
        }
        if (this.domainFormData.service.protocol === "http") {
          this.domainFormData.upstream.targets.forEach(item => {
            if (item.target.split(':')[1] == 443) {
              const errorMessage = this.$t('message.UpstreamInvalid', { target: item.target, port: item.target.split(':')[1], protocol: 'http' });
              this.notifyError(errorMessage);
              return;
            }
          });
        } else if (this.domainFormData.service.protocol === "https") {
          this.domainFormData.upstream.targets.forEach(item => {
            if (item.target.split(':')[1] == 80 || item.target.split(':')[1] == 8080) {
              const errorMessage = this.$t('message.UpstreamInvalid', { target: item.target, port: item.target.split(':')[1], protocol: 'https' });
              this.notifyError(errorMessage);
              return;
            }
          });
          if (!this.domainFormData.upstream || !this.domainFormData.upstream.targets || this.domainFormData.upstream.targets.length === 0) {
            this.notifyError(this.$t('message.MissingUpstream'));
            this.modalState = MODAL_STATES.IDLE;
            return;
          }
        }
        
        if (this.field_errors['upstream'].length) {
          this.modalState = MODAL_STATES.IDLE;
          return;
        }
      }

      if (this.domainFormData.plugins) {
        const gaiusUpstreamHost = this.domainFormData.plugins.find((p) => {
            if (p.name == 'gaius-upstream-host' && p.enabled && p.config.hostname) {
              return p;
            }
        });

        if (gaiusUpstreamHost) {
            if (!isValidDomain(gaiusUpstreamHost.config.hostname)) {
                this.modalState = MODAL_STATES.IDLE;
                return;
            }
        }
      }

      this.plugin_errors = null;
      this.field_errors = null;

      this.$refs.basicConfiguration.addTarget(true); 

      this.modalState = MODAL_STATES.SAVING;

      try {
        await axios({
          method: this.sourceRule.isNew() ? "POST" : "PATCH",
          url: this.sourceRule.isNew()
              ? `domain/rules/`
              : `domain/rules/${this.sourceRule.getId()}/`,
          data: {
            domain: this.domainFormData,
            meta: {cname: this.$store.getters.cname, description: this.description}
          },
        });
      } catch ({error, errors}) {
        const errorData = error.response.data;
        this.field_errors = errorData.field_errors;
        this.plugin_errors = errorData.plugins;
        
        if (this.plugin_errors != null) {
            this.notifyError(this.$t('rule.RuleSavingFailed'));
            this.modalState = MODAL_STATES.IDLE;
            return;
        }
        if (errorData.non_field_errors){
          this.field_errors = this.field_errors || {};
          this.field_errors['rule_name'] = this.$t('rule.RuleNameInputError');
          
          this.notifyError(this.$t('rule.RuleSavingFailed'));
          this.modalState = MODAL_STATES.IDLE;
          return;
        }

        this.modalState = MODAL_STATES.IDLE;
        return;
      }

      this.notifySuccess(this.$t('rule.RuleSavedSuccessfully'));
      this.modalState = MODAL_STATES.IDLE;
      this.$emit('refresh');
      this.handleCloseModal();
    },
    handleChangedTab(activeTab) {
      this.activeTab = activeTab;
    },
    handleGuardConfigPendingValues(pendingValues) {
      Object.assign(this.guardConfigPendingValues, pendingValues);
    },
    handleRedirectDomainAction(enabled) {
      const plugins = this.domainFormData.plugins;

      // already exist. bail out.
      if (enabled && plugins.find((p) => p.name === PLUGIN_NAMES.GAIUS_REDIRECT))
        return;

      if (enabled) {
        this.domainFormData.plugins = [...plugins, {
          name: PLUGIN_NAMES.GAIUS_REDIRECT,
          config: {
            redirect_url: '',
          },
          enabled: true,
        }];
      } else {
        this.domainFormData.plugins = plugins.filter((p) => p.name !== PLUGIN_NAMES.GAIUS_REDIRECT);
      }
    },
    handleRedirectUrlUpdate(redirectUrl) {
      const plugins = this.domainFormData.plugins;

      this.domainFormData.plugins = plugins.map((p) => {
        if (p.name === PLUGIN_NAMES.GAIUS_REDIRECT) {
          const newPlugin = {...p};
          newPlugin.config.redirect_url = redirectUrl;

          return newPlugin;
        }
        return p;
      });
    },
  }
};
</script>
