<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>

          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol>
                  <h3>
                    <span>{{ $t('rule.DomainRules') }}</span>
                  </h3>
                </CCol>
              </CRow>              
              <CRow>
                <CCol sm="2">
                  <CInput
                      id="rule-search"
                      type="text"
                      :placeholder="$t('Search')"
                      size="sm"
                      :value.sync="domainQ"
                  >
                    <template #append>
                      <CButton type="submit" color="primary" @click="doSearch()">{{ $t('Search') }}</CButton>
                    </template>
                  </CInput>
                </CCol>
                <CCol auto class="mr-auto">
                </CCol>
                <CCol sm="6">
                  <CButtonGroup class="float-right">
                    <CButton color="success" id="add-rule" @click="addDomain">
                      <CIcon name="cil-plus"/>
                      {{ $t("rule.AddRule") }}
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>

          <CCardBody>
            <CDataTable
                :hover="hover"
                :striped="striped"
                :bordered="bordered"
                :fixed="fixed"
                :items="ruleList"
                :fields="ruleListFields"
                :dark="dark"
                :loading="listLoading"
                :items-per-page="perpage"
                :items-per-page-select='{"label": $t("NumberOfItemsPerPage"), "values": [10,25,50]}'
                @pagination-change="pageSizeChanged"
                ref="ruleTable"
            >

              <template #name="{item}">
                <td>
                  <span class="has-icon">
                    {{ item.getName() }}
                  </span>
                </td>
              </template>

              <template #actions="{item}">
                <td>
                  <CButtonGroup v-show="enableSelect===false">
                    <CButton
                        id="rule-edit"
                        color="primary"
                        variant="outline"
                        square
                        @click="EditRule(item)"
                        class="float-right"
                    >{{ $t('rule.Edit') }}
                    </CButton>
                  </CButtonGroup>
                </td>
              </template>

            </CDataTable>
            
            <CPagination
                :activePage.sync="page"
                :pages="total_pages"
                align="center"
                @update:activePage="refresh"
            />
          </CCardBody>

        </CCard>
      </CCol>
    </CRow>

    <EditRule :source-rule="selectedDomain"
                @refresh="refresh"
                :editShow="activeModal === modalFlags.EDIT"
                @hide="activeModal = null"
                @onStopConfirmModal="attachDomainWsHandler"/>

    <PatchDomain ref="patchDomainModal"
                 :domains="selectedRows"
                 @save="patchSave"
                 @refresh="refresh"
                 @hide="endSelect"
                 :patchShow.sync="patchModal"
                 id="patchModal"
                 :queueDomainDeactivate.sync="queueDomainDeactivate"
                 :onProgressDomainDeactivate.sync="onProgressDomainDeactivate"
                 :queueDomainResume.sync="queueDomainResume"
                 :onProgressDomainResume.sync="onProgressDomainResume"
                 :queueDomainDelete.sync="queueDomainDelete"
                 :onProgressDomainDelete.sync="onProgressDomainDelete"
                 @onResume="onPatchResume"
                 @onDelete="onPatchDelete"
                 @onDeactivate="onPatchDeactivate"/>

  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import EditRule from "./EditRule/EditRule";
import PatchDomain from "./PatchDomain";

import "driver.js/dist/driver.min.css"
import getDefaultDomainConfig from '@/utilities/getDefaultDomainConfig';
import {
  getRules,
} from '../../utilities/api';
import {isArray} from 'lodash';
import {DomainAction} from '@/utilities/websocket';
import {ExportDomainMixin, COLUMN_KEYS} from '@/mixins/ExportDomainMixin';
import {MIXED_VALUE} from '@/utilities/constants';
import {makeRules} from '../../models/RuleFactory';
import {Rule} from '../../models/Rule';
import Fuse from 'fuse.js';
import ExclusiveCnamePopover from '@/views/domain/ExclusiveCnamePopover';
import CDataTableCustom from '@/views/domain/CDataTableCustom';

const MODAL_FLAGS = {
  EDIT: 'edit',
  SSL_CONFIG: 'ssl_config',
  CONFIGURE_CNAME: 'configure_cname',
};

export default {
  name: "Rules",
  mixins: [ExportDomainMixin],
  components: {
    ExclusiveCnamePopover,
    EditRule,
    PatchDomain,
    CDataTableCustom
  },
  watch: {
    editModal() {
      if (!this.editModal) {
        this.attachDomainWsHandler();
      }
    },
  },
  computed: {
    isPaidCname: function () {
      return !this.isFreeCname;
    },
    isFreeCname: function () {
      const subscriptions = this.$store.state.subscriptions;
      for (let s of subscriptions) {
        if (!s.plan)
          return true;
      }

      return false;
    },
    cname: function () {
      return this.$store.getters.cname;
    },

    domain_ws: {
      get() {
        return this.$store.state.websocket.domain_ws;
      },
      set(value) {
        this.$store.state.websocket.domain_ws = value;
      }
    },
    selectedDomains: function () {
      return this.items.filter(i => i._selected === true);
    },
    ruleListFields: function () {
      let columns = [
        {
          key: COLUMN_KEYS.NAME,
          label: this.$t("rule.Name"),
          _style: "width:70%",
          sorter: false,
          filter: false,
        },
        {
          key: "actions",
          label: "",
          _style: "width:30%",
          sorter: false,
          filter: false
        },
      ];

      return columns;
    },
    ruleList: function () {
      if (this.searchTerm.length) {
        return this.searchResult;
      }

      return this.rules;
    },
    routeMap: function () {
      const map = {};
      for (let r of this.rules) {
        map[r.getId()] = r;
      }

      return map;
    },
  },

  data() {
    return {
      items: [],
      rules: [],
      driver: null,
      searchTerm: "",
      searchResult: [],
      enableSelect: false,
      total_rows: 0,
      total_pages: 10,
      current_page_offset: null,
      next_page_offset: null,
      previous_page_offset: null,
      columnFilter: {
        external: true, lazy: true
      },
      dg: null,
      show: false,
      listLoading: false,
      queryParams: {},
      page: 1,
      perpage: 10,
      editModal: false,
      patchModal: false,
      sslModal: false,
      defaultDomain: this.getDefaultDomain(),
      selectedDomain: new Rule(),      
      domainSubscription: {},
      queueDomainDeploy: [],
      onProgressDomainDeploy: [],
      queueDomainDeactivate: [],
      onProgressDomainDeactivate: [],
      queueDomainResume: [],
      onProgressDomainResume: [],
      queueDomainDelete: [],
      onProgressDomainDelete: [],
      activeModal: null,
      modalFlags: MODAL_FLAGS,
      selectedRows: [],
      certStatus: null,
      domainStatus: null,
      domainQ: null
    };
  },
  props: {
    caption: {
      type: String,
      default: 'domain.DomainRules'
    },
    group: Object,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  mounted() {
    this.refresh();
  },

  methods: {
    pageSizeChanged(pageSize) {
      this.page = 1;
      this.perpage = pageSize;
      this.refresh();
    },
    showDomainTooltip(domain, content, type, delay = 5000) {
      domain._message = {
        content: content,
        show: true,
        type: type
      }
      this.$refs.ruleTable.$forceUpdate();

      setTimeout(() => {
        domain._message.show = false;
        this.$refs.ruleTable.$forceUpdate();
      }, delay)
    },

    onPatchResume() {
      this.onProgressDomainResume.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionResume'), 'success');
      })
    },
    onPatchDeactivate() {
      this.onProgressDomainDeactivate.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDeactivate'), 'success');
      })
    },
    onPatchDelete() {
      this.onProgressDomainDelete.forEach(domain => {
        domain = this.items.find(d => d.name === domain.name);
        this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
      })
    },
    attachDomainWsHandler() {
      this.domain_ws.onMessage = function (event, result) {
        if (result.data.hasOwnProperty('error')) {
          let domain = this.items.find(d => d.name === result.data.domain.name);
          this.$refs.ruleTable.$forceUpdate();
          this.showDomainTooltip(domain, result.data.error, 'danger', 8000)
          return;
        }
        let domain = this.items.find(d => d.name == result.data.domain.name);
        if (result.action === DomainAction.DOMAIN_DELETE) {
          if (result.data.dp.success) {
            if (result.data.dp.result.success) {
              this.showDomainTooltip(domain, this.$i18n.t('DeleteActionSuccessful'), 'success', 8000);
              this.items = this.items.filter(item => item.name !== domain.name);
            } else {
            }

            // Remove item from queue
            if (this.queueDomainDelete.length === 0) {
              this.$refs.patchDomainModal.processing = false;
            }
            this.queueDomainDelete = this.queueDomainDelete.filter(item => item.name !== domain.name);
            this.onProgressDomainDelete = this.onProgressDomainDelete.filter(item => item.name !== domain.name);
            const domain_deploy = this.queueDomainDelete.shift();
            if (domain_deploy) {
              this.onProgressDomainDelete.push(domain_deploy);
              this.sendDeploy(DomainAction.DOMAIN_DELETE, [domain_deploy]);
            }

            this.$refs.ruleTable.$forceUpdate();
          }
        }
      }.bind(this);
    },

    sendDeploy(action, domains) {
      var domain_names = domains.map(d => d.name)
      if (action === DomainAction.DOMAIN_DELETE) {
        domains.forEach(domain => {
          domain = this.items.find(d => d.name === domain.name);
          this.showDomainTooltip(domain, this.$i18n.t('DeployActionDelete'), 'success');
        });
      }

      this.domain_ws.sendMessage(action, {domains: domain_names})
    },

    endSelect() {
      this.enableSelect = false;
      this.patchModal = false;
      this.ruleList.forEach((item) => {
        this.$set(item, '_selected', false)
        this.$set(item, '_classes', "")
      });
      this.selectedRows = [];
    },

    getDefaultDomain() {
      return getDefaultDomainConfig();
    },
    patchSave(domain) {
      //別問我為什麼要這麼寫，我也看不懂。
      (async () => {
        for (const item of this.selectedDomains) {
          if (domain.sourceConfig.full_url === MIXED_VALUE) {
            // noop
          } else if (domain.sourceConfig.full_url) {
            item.sourceConfig.full_url = domain.sourceConfig.full_url
          }

          if (domain.sourceConfig.redirect === MIXED_VALUE) {
            // noop
          } else {
            item.sourceConfig.redirect = domain.sourceConfig.redirect;
          }

          // domain.strategies is null if the selected domains have different advanced rules.
          // if domain.strategies is null we retain the selected domain advanced rules.
          if (domain.strategies === null) {
            // noop
          } else if (isArray(domain.strategies)) {
            item.strategies = domain.strategies
          } else if (domain._strategies_cleanall) {
            item.strategies = []
          }

          if (domain.cacheConfig.cache_type) {
            item.cacheConfig.cache_type = domain.cacheConfig.cache_type;
            item.cacheConfig.nocache = domain.cacheConfig.nocache || [];
          }

          if (domain.guardConfig) {
            item.guardConfig = {...item.guardConfig, ...domain.guardConfig};
          }

          // promises.push(
          let response = await axios({
            method: "PATCH",
            url: `domain/{domain}/`,
            data: item,
            urlParams: {
              domain: item.name
            }
          }).catch(({errors}) => {
            errors.forEach((message) => {
              this.flash(message, 'error', {"timeout": 5000});
            });
          })
          if (response.status == "200") {
            this.flash(item.name + " " + this.$t("domain.SaveSuccess"), "success", {
              timeout: 5000
            });
          }
        }
      })().then(() => {
        this.endSelect();
        this.refresh();
      })
    },
    doSearch() {
      this.page = 1;      
      this.refresh();
    },
    addDomain() {
      this.selectedDomain = new Rule();
      this.activeModal = MODAL_FLAGS.EDIT;
    },
    refresh() {
      if (this.cname) {
        this.fetchRules(this.current_page_offset);
      }
      this.enableSelect = false;
    },
    EditRule(domain) {
      this.selectedDomain = domain;
      this.activeModal = MODAL_FLAGS.EDIT;
    },
    patchDomain() {
      if (this.selectedRows.length == 0) {
        this.flash(this.$t('message.PleaseCheckTheDomainNameFirst'), "error", {timeout: 3000});
        return
      }
      this.patchModal = false;
      this.patchModal = true;
    },  
    fetchRules() {
      this.listLoading = true;

      return getRules({
        page: this.page, 
        perpage: this.perpage,
        q: this.domainQ
      })
          .then((response) => {
            const responseData = response.data;
            this.rules = makeRules(responseData.rules);
            this.total_pages = responseData.total_pages;
          })
          .finally(() => {
            this.listLoading = false;
          });
    },
    getRules(serviceId) {
      return this.routeMap[serviceId].hosts;
    },   
  }
};
</script>

<style scoped>
.GrayLock {
  color: gray;
}

.GreenLock {
  color: green;
}

.source-config-value {
  display: flex;
  align-items: center;
}

.source-config-value span {
  padding-left: 6px;
}

#filterBox {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  display: none;
}

#filterBox.Show {
  display: flex !important;
}
</style>
