<template>
  <div>    
    <a-card>
      <div>
        <p class="mb-1 has-icon" v-bind:class="ruleNameErrorClass">
          <a-icon v-if="!domainEditDisabled" type="form" class="mr-1"/>
          <strong>{{ $t('rule.Name') }}
            <a-tooltip :title="$t('message.FieldRequired')">
              <span v-if="!domainEditDisabled" class="text-danger">*</span>
            </a-tooltip>
          </strong>
        </p>
        
        <a-alert v-if="hasFieldError('rule_name')" :message="ruleNameError" type="error" :banner="true"/>
      </div>
      <div class="mt-2">
        <CInput
            id="domain_name"
            :placeholder="$t('rule.PleaseEnterTheRuleName')"
            :value="domain"
            @input="$emit('update:domain', $event)"
            style="margin-bottom: 6px"
        ></CInput>
      </div>
      <div>
        <p class="mb-1 has-icon">
          <strong>{{ $t('rule.RuleInputDescription') }}</strong>
        </p>
      </div>
      <div class="mt-2">
        <CInput
            id="domain_description"
            :placeholder="$t('rule.RuleInputDescription')"
            :value="description"
            @input="$emit('update:description', $event)"
            style="margin-bottom: 6px"
        ></CInput>        
      </div>
    </a-card>
    
    <a-card v-show="domainAction === trafficActionUpstream" :title="$t('domain.Upstream')" class="mt-4">
      <p class="mb-1"><strong>{{ $t('domain.UpstreamTargets') }}</strong></p>
      <p>{{ $t('message.UpstreamTargetsDescription') }}</p>

      <small class="mt-4 mb-1 text-muted">{{ $t('message.UpstreamTargetsList') }}</small>
      <div v-if="upstreamTargets.length">
        <CListGroup>
          <CListGroupItem v-for="(target) in upstreamTargets" v-bind:key="target.target">
            <div style="display: flex; align-items: center;">
              <div style="flex: 1">
                {{ target.target }}
              </div>
              <div class="shadow-list-action-btns">
                <CButtonClose v-on:click="removeTarget(target)"/>
              </div>
            </div>
          </CListGroupItem>
        </CListGroup>
      </div>
      <SimpleEmpty v-else>
        <span slot="description">{{ $t('message.NoUpstreamTargetsAdded') }}</span>
      </SimpleEmpty>

      <div class="mt-4 p-4 border-top">
        <p class="mb-1 has-icon text-info"
           v-bind:class="{'text-info': !hasFieldError('upstream_targets'), 'text-danger': hasFieldError('upstream_targets')}">
          <a-icon type="form" class="mr-1"/>
          <strong>{{ $t('message.AddNewTarget') }}
          </strong>
        </p>
        <p>
          {{ $t('message.UpstreamTargetDescription') }}
        </p>

        <CTextarea
            id="add_new_target"
            v-model="newTargetName"
            placeholder="20.1.30.4:8001"
            :description="$t('InputFormat') + ': 20.1.30.4:8001 or www.domain.com'"
        >
        </CTextarea>

        <div class="d-flex justify-content-end mt-n4">
          <a-button @click="addTarget()" :disabled="!newTargetName.length">
            <a-icon type="plus"/>
            {{ $t('Add') }}
          </a-button>
        </div>
      </div>

      <div class="mt-4 p-4 border-top">
        <p class="mb-1 has-icon text-info"
           v-bind:class="{'text-info': !hasFieldError('protocol'), 'text-danger': hasFieldError('protocol')}">
          <a-icon type="form" class="mr-1"/>
          <strong>{{ $t('domain.UpstreamProtocol') }}
          </strong>
        </p>
        <p>{{ $t('message.UpstreamProtocolDescription') }}</p>
        <a-button-group>
          <a-button @click="$emit('update:service-protocol', 'http')"
                    :type="serviceProtocol === 'http' ? 'primary' : 'default'">HTTP
          </a-button>
          <a-button @click="$emit('update:service-protocol', 'https')"
                    :type="serviceProtocol === 'https' ? 'primary' : 'default'">HTTPS
          </a-button>
        </a-button-group>

        <p v-if="checkingDomainUpstreamProtocol" class="mt-2 mb-0 d-flex justify-content">
          <a-icon type="loading" class="mt-1"/>
          <span class="ml-1">{{ $t('message.DetectingUpstreamProtocol') }}</span>
        </p>
      </div>
    </a-card>
    <a-card v-show="domainAction === trafficActionRedirect" :title="$t('domain.RedirectTraffic')" class="mt-4">
      <div>
        <p class="mb-1 has-icon text-info"
           v-bind:class="{'text-info': true, 'text-danger': false}">
          <a-icon type="form" class="mr-1"/>
          <strong>{{ $t('domain.RedirectToUrl') }}
            <a-tooltip :title="$t('message.FieldRequired')">
              <span class="text-danger">*</span>
            </a-tooltip>
          </strong>
        </p>
        <p>{{ $t('message.SpecifyRedirectUrl') }}</p>
        <a-form-item :validate-status="redirectUrlValidateStatus" :help="redirectUrlInputHelpMessage">
          <a-input v-model="syncRedirectUrl"
                   class="w-100"
                   placeholder="https://www.example.com"/>
        </a-form-item>
      </div>
    </a-card>
  </div>
</template>

<script>
import validUrl from 'valid-url';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import {DOMAIN_TRAFFIC_ACTIONS, MIXED_VALUE, MULTIPLE_VALUES_SPLITTER} from '@/utilities/constants';
import SimpleEmpty from '@/views/domain/SimpleEmpty';
import {getDomainVerifyUpstreamProtocol} from '@/utilities/api';
import { bus } from '@/main';

export default {
  name: 'BasicConfiguration',
  components: {SimpleEmpty},
  mixins: [DomainValidationMixin],
  props: [
    'domain',
    'domainEditDisabled',
    'upstreamTargets',
    'fieldErrors',
    'serviceProtocol',
    'domainAction',
    'redirectUrl',
    'ruleName',
    'description',
  ],
  data() {
    return {
      newTargetName: "",
      checkingDomainUpstreamProtocol: false,
    }
  },
  created() {
    bus.$on('emptyNewTargetName', () => {
      this.newTargetName = '';
    });
  },
  computed: {
    redirectUrlValidateStatus: function () {
      if (this.syncRedirectUrl === '')
        return '';

      if (!validUrl.isWebUri(this.syncRedirectUrl)) {
        return 'error';
      }

      return '';
    },
    redirectUrlInputHelpMessage: function () {
      if (this.redirectUrlValidateStatus === 'error') {
        return this.$t('message.URLFormatInvalid');
      }

      return '';
    },
    syncRedirectUrl: {
      get() {
        return this.redirectUrl;
      },
      set(value) {
        this.$emit('update:redirect-url', value);
      }
    },
    ruleNameError: function () {
      if (!this.hasFieldError('rule_name'))
        return false; 

      return this.fieldErrors['rule_name'];
    },
    ruleNameErrorClass: function () {
      if (this.domainEditDisabled)
        return '';

      if (this.hasFieldError('rule_name'))
        return 'text-danger';

      return 'text-info';
    },
    trafficActionUpstream: function () {
      return DOMAIN_TRAFFIC_ACTIONS.UPSTREAM;
    },
    trafficActionRedirect: function () {
      return DOMAIN_TRAFFIC_ACTIONS.REDIRECT;
    },
  },
  methods: {
    hasFieldError: function (field) {
      if (!this.fieldErrors)
        return false;

      return Boolean(this.fieldErrors[field]);
    },
    isDomainValid: function (domain) {
      // return undefined to not show the validation styling
      if (this.hasFieldError('domain') || this.domainEditDisabled || !domain || !domain.length) {
        return;
      }

      return this.domain_validator(domain);
    },
    async addTarget(suppressErrorMessage = false) {
      this.newTargetName = this.newTargetName.trim();
      if (!this.newTargetName.length) {
        return;
      }

      const targets = [...this.upstreamTargets];
      const rawTargets = this.newTargetName.split(MULTIPLE_VALUES_SPLITTER);

      for (let t of rawTargets) {
        if (t.split(':').length - 1 > 1) {
          if (!this.source_ipv6_validator(t)) {            
            !suppressErrorMessage && this.notifyError(this.$t('message.IsNotAValidHostnameOrIpFormat', [t]), this.$t('message.InputDataVerificationError'));
            continue;
          }           
        } else {
          if (!this.source_hostname_validator(t) && !this.source_ipv4_validator(t) && !this.source_ipv6_validator(t)) {
            !suppressErrorMessage && this.notifyError(this.$t('message.IsNotAValidHostnameOrIpFormat', [t]), this.$t('message.InputDataVerificationError'));
            continue;
          }
        }  

        // add default port 80 if not specified
        let hasNoPort = t.indexOf(':') < 0;
        if (hasNoPort) {
          t = t + ':80';
        }

        const duplicate = targets.find((_t) => _t.target === t);
        if (duplicate) {
          !suppressErrorMessage && this.notifyError(this.$t('message.TargetDuplicate', [t]));
          continue;
        }

        targets.push({target: t});
      }

      this.$emit('update:upstream-targets', targets);
      this.verifyUpstreamTargets(targets);
      this.newTargetName = '';
    },
    removeTarget(target) {
      const targets = this.upstreamTargets.filter((t) => t.target !== target.target);

      this.$emit('update:upstream-targets', targets);
      this.verifyUpstreamTargets(targets);
    },
    handleDomainActionChange(action) {
      this.$emit('toggle-redirect-domain-action', action === this.trafficActionRedirect);
    },
    isMixedValue(value) {
      return value === MIXED_VALUE;
    },
    catchInvalidChars(e) {
      if (e.code === 'Space' || e.code === 'Comma') {
        e.preventDefault();
      }
    },
    verifyUpstreamTargets(upstreamTargets) {
      if (!upstreamTargets.length) {
        return;
      }

      const upstreams = upstreamTargets.map(n => n.target);
      this.checkingDomainUpstreamProtocol = true;

      getDomainVerifyUpstreamProtocol(upstreams.join(','))
        .then(response => {
          this.checkingDomainUpstreamProtocol = false;

          if (response.data['https']) {
            this.$emit('update:service-protocol', 'https');
          } else if (response.data['http']) {
            this.$emit('update:service-protocol', 'http');
          } else {
            for (const n of upstreamTargets) {
              const target = n.target;
              const segments = target.split(':');
              const port = segments[1];

              if (port === '80') {
                this.$emit('update:service-protocol', 'http');
              } else if (port === '443') {
                this.$emit('update:service-protocol', 'https');
              }
            }
          }
        });
    },
  }
}
</script>

<style scoped>
/* domain action related */
.traffic-actions {
  display: flex;
  border: solid 1px #d8dbdf;
  border-radius: 3px;
}

.traffic-action {
  display: flex;
  padding: 12px 16px 0 16px;
}

.traffic-action:first-child {
  border-right: solid 1px #d8dbdf;
  border-bottom: solid 1px #d8dbdf;
}

.traffic-action > div:first-child {
  padding-top: 1px;
  margin-right: 10px;
}

.traffic-action label {
  font-weight: 500;
  margin-bottom: 0;
}

.traffic-action label + p {
  color: rgba(107, 114, 128, 1);
}
</style>
